<template>
  <div class="document">

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child notification is-primary">
          <p class="title">
            Bestellung {{ data.document_nr ? data.document_nr : "" }}
            </p>
          <p class="subtitle">{{ data.supplier.name }}</p>
          <div class="content">
            <!-- Content -->
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification">
              <p class="title">Lieferant</p>

<!--              <CustomerPicker v-model="data.customer" :disabled="data.sent"></CustomerPicker>-->

              <div class="card">
                <div class="card-content">
                  <strong>{{ data.supplier.name }}</strong><br /><br />
                  per E-Mail: {{ data.supplier.email_order }}
                </div>
              </div>

<!--              <p class="subtitle">Anschrift</p>-->

<!--              <div v-if="!data.sent">-->
<!--                <b-field>-->
<!--&lt;!&ndash;                  <b-input placeholder="Anrede" v-model="data.to_salutation" ></b-input>&ndash;&gt;-->
<!--                  <SalutationPicker v-model="data.to_salutation" :disabled="data.sent" />-->
<!--                </b-field>-->

<!--                <b-field>-->
<!--                  <b-input placeholder="Name" v-model="data.to_name" :disabled="data.sent"></b-input>-->
<!--                </b-field>-->

<!--                <b-field>-->
<!--                  <b-input placeholder="Anschrift" v-model="data.to_street" :disabled="data.sent"></b-input>-->
<!--                </b-field>-->

<!--                <b-field>-->
<!--                  <b-input placeholder="PLZ" v-model="data.to_zip" :disabled="data.sent"></b-input>-->
<!--                  <b-input placeholder="Ort" v-model="data.to_place" :disabled="data.sent"></b-input>-->
<!--                </b-field>-->

<!--                <b-field>-->
<!--                  <b-input placeholder="Land" v-model="data.to_country" :disabled="data.sent"></b-input>-->
<!--                </b-field>-->
<!--              </div>-->
<!--              <div v-if="data.sent" class="card">-->
<!--                <div class="card-content">-->
<!--                  {{ data.to_salutation }}<br />-->
<!--                  <b>{{ data.to_name }}</b><br />-->
<!--                  {{ data.to_street }}<br />-->
<!--                  {{ data.to_plz }} {{data.to_place}}<br /><br />-->
<!--                  {{ data.to_country }}-->
<!--                </div>-->
<!--              </div>-->
            </article>

          </div>
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title">Lieferung</p>
<!--              <ProjectPicker v-model="data.project" :disabled="data.sent"></ProjectPicker>-->

              <div class="card" v-if="data.use_delivery_address">
                <div class="card-content">
                  <b-input type="textarea" v-model="data.delivery_address" :disabled="data.sent"></b-input>
<!--                  <span style="white-space: pre;">{{data.delivery_address}}</span>-->
                </div>
              </div>

<!--              <b-field class="mt-4">-->
<!--                <b-switch size="is-medium" v-model="data.use_delivery_address" :disabled="data.sent">Lieferadresse</b-switch>-->
<!--              </b-field>-->

<!--              <div v-if="data.use_delivery_address">-->
<!--                <div v-if="!data.sent">-->
<!--                  <b-field>-->
<!--&lt;!&ndash;                    <b-input placeholder="Anrede" v-model="data.delivery_salutation" ></b-input>&ndash;&gt;-->
<!--                    <SalutationPicker v-model="data.delivery_salutation" :disabled="data.sent"/>-->
<!--                  </b-field>-->

<!--                  <b-field>-->
<!--                    <b-input placeholder="Name" v-model="data.delivery_name" :disabled="data.sent"></b-input>-->
<!--                  </b-field>-->

<!--                  <b-field>-->
<!--                    <b-input placeholder="Anschrift" v-model="data.delivery_street" :disabled="data.sent"></b-input>-->
<!--                  </b-field>-->

<!--                  <b-field>-->
<!--                    <b-input placeholder="PLZ" v-model="data.delivery_zip" :disabled="data.sent"></b-input>-->
<!--                    <b-input placeholder="Ort" v-model="data.delivery_place" :disabled="data.sent"></b-input>-->
<!--                  </b-field>-->

<!--                  <b-field>-->
<!--                    <b-input placeholder="Land" v-model="data.delivery_country" :disabled="data.sent"></b-input>-->
<!--                  </b-field>-->
<!--                </div>-->
<!--                <div v-if="data.sent" class="card">-->
<!--                  <div class="card-content">-->
<!--                    {{ data.delivery_salutation }}<br />-->
<!--                    <b>{{ data.delivery_name }}</b><br />-->
<!--                    {{ data.delivery_street }}<br />-->
<!--                    {{ data.delivery_plz }} {{data.delivery_place}}<br /><br />-->
<!--                    {{ data.delivery_country }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->



            </article>
          </div>

          <div class="tile is-parent">
            <article class="tile is-child notification">
              <div class="content">
                <p class="title">Sonstiges</p>

                <b-field label="Datum:">

                  <DatePicker v-model="data.date" disabled="true"></DatePicker>

                </b-field>

<!--                <b-field label="Leistungsdatum/-zeitraum:" v-if="document_type == 'invoice'">-->
<!--                  <b-input v-model="data.supply_time" v-if="!data.sent"></b-input>-->
<!--                  <span v-if="data.sent">{{data.supply_time}}</span>-->
<!--                </b-field>-->

                <p class="subtitle">Benutzer</p>
                <b-field>
<!--                  <UserPicker v-model="data.responsible_person" v-if="!data.sent"></UserPicker>-->
                  <span>{{data.responsible_person.full_name}}</span>
                </b-field>

                <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">
                  <template #trigger="props">
                    <a aria-controls="contentIdForA11y1">
                      <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                      {{ !props.open ? 'Sachbearbeiter-Details...' : 'Details schließen...' }}
                    </a>
                  </template>

                  <div class="card-content">
                    <div class="content">


                      <b-field label="Name:">
                        <b-input v-model="data.responsible_person_name" v-if="!data.sent"></b-input>
                        <span v-if="data.sent">{{data.responsible_person_name}}</span>
                      </b-field>

                      <b-field label="Telefon:">
                        <b-input v-model="data.responsible_person_phone" v-if="!data.sent"></b-input>
                        <span v-if="data.sent">{{data.responsible_person_phone}}</span>
                      </b-field>

                      <b-field label="Mobil:">
                        <b-input v-model="data.responsible_person_mobile" v-if="!data.sent"></b-input>
                        <span v-if="data.sent">{{data.responsible_person_mobile}}</span>
                      </b-field>

                      <b-field label="Standort:">
                        <b-input v-model="data.responsible_person_location" v-if="!data.sent"></b-input>
                        <span v-if="data.sent">{{data.responsible_person_location}}</span>
                      </b-field>

                      <b-field label="E-Mail:">
                        <b-input v-model="data.responsible_person_email" v-if="!data.sent"></b-input>
                        <span v-if="data.sent">{{data.responsible_person_email}}</span>
                      </b-field>
                    </div>
                  </div>
                </b-collapse>

<!--                <p class="subtitle">Zahlungsziel</p>-->

<!--                <div class="content">-->
<!--                  <PaymentConditionPicker v-model="data.payment_condition" v-on:change="data.payment_condition_text = data.payment_condition.text" :hidden="data.sent"></PaymentConditionPicker>-->
<!--                  <div :hidden="!data.sent"><h6>{{data.payment_condition.short}}:</h6><p>{{data.payment_condition_text}}</p></div>-->
<!--                  <b-field :hidden="data.sent">-->
<!--                    <b-input type="textarea" v-model="data.payment_condition_text" :disabled="data.sent"></b-input>-->
<!--                  </b-field>-->
<!--                </div>-->

<!--                <b-field label="Rabatt(%)" horizontal>-->
<!--                  <b-numberinput controls-position="compact" v-model="data.sales_discount" step="0.1" :disabled="data.sent"></b-numberinput>-->
<!--                </b-field>-->

<!--                <b-field label="Skonto(%)" horizontal v-if="document_type != 'creditnote'">-->
<!--                  <b-numberinput controls-position="compact" v-model="data.cash_discount" step="0.1" :disabled="data.sent"></b-numberinput>-->
<!--                </b-field>-->

<!--                <div :hidden="document_type != 'invoice'">-->
<!--                  <p class="subtitle">Rechnungstyp</p>-->
<!--                  <div :hidden="data.sent">-->
<!--&lt;!&ndash;                    <InvoiceTypePicker v-model="data.type" :hidden="data.sent"></InvoiceTypePicker>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div :hidden="!data.sent">-->
<!--                    <b-tag class="is-primary is-large" v-if="data.invoice_type == 'InvoiceType.final'">Schlussrechnung</b-tag>-->
<!--                    <b-tag class="is-success is-large" v-if="data.invoice_type == 'InvoiceType.total'">Einzelrechnung</b-tag>-->
<!--                    <b-tag class="is-warning is-large" v-if="data.invoice_type == 'InvoiceType.interim'">Abschlagsrechnung</b-tag>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <p class="subtitle">Besonderheiten</p>-->
<!--                <b-field>-->
<!--                  <b-checkbox v-model="reverse_charge" disabled>-->
<!--                    Steuerschuldumkehr nach § 13b UStG-->
<!--                  </b-checkbox>-->
<!--                </b-field>-->

<!--                <div class="content">-->
<!--                  <div class="block">-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="1">-->
<!--                        Reguläre Umsatzsteuer-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="2">-->
<!--                        Innergemeinschaftliche Leistung/Lieferung-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="3">-->
<!--                        Steuerschuldumkehr nach § 13b UStG (Reverse Charge)-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <p class="subtitle">Total</p>

                <table class="table">
                  <tr>
                    <td><b>Summe netto</b></td>
                    <td style="text-align: right">{{ formatCurrency(total_net) }}</td>
                  </tr>
<!--                  <tr style="" :hidden="!data.sales_discount">-->
<!--                    <td><b>Rabatt ({{data.sales_discount}}%)</b></td>-->
<!--                    <td style="text-align: right">-{{ formatCurrency(total_discount) }}</td>-->
<!--                  </tr>-->
                  <tr style="border-bottom: solid 2px">
                    <td><b>Umsatzsteuer</b></td>
                    <td style="text-align: right">{{ formatCurrency(total_vat) }}</td>
                  </tr>
                  <tr style="border-bottom: double">
                    <td><b>Summe brutto</b></td>
                    <td style="text-align: right"><b>{{ formatCurrency(total_gross) }}</b></td>
                  </tr>
<!--                  <tr :hidden="!data.cash_discount">-->
<!--                    <td>Skonto ({{data.cash_discount}}%)</td>-->
<!--                    <td style="text-align: right">{{ formatCurrency(total_cash_discount * -1)}}</td>-->
<!--                  </tr>-->
<!--                  <tr :hidden="!data.cash_discount">-->
<!--                    <td>Summe abzgl. Skonto</td>-->
<!--                    <td style="text-align: right">{{ formatCurrency(total_gross - total_cash_discount) }}</td>-->
<!--                  </tr>-->
                </table>

              </div>
            </article>
          </div>
        </div>

        <div class="tile is-vertical is-16">
          <div class="tile">


              <div class="tile is-parent is-8">
                <article class="tile is-child notification">
<!--                  <p class="title">Positionen</p>-->
                  <div class="content">
                    <b-field>
                      <b-input type="textarea" v-model="data.free_text_pre" :disabled="data.sent"></b-input>
                      <template #label>
                        Freitext
<!--                        <b-button size="is-small" icon-left="plus" class="ml-5" @click="openBoilerplateComposer" v-if="!data.sent">Textbausteine</b-button>-->
                      </template>
                    </b-field>

                  </div>
                </article>
              </div>


            <div class="tile is-parent">
              <article class="tile is-child notification">
<!--                <p class="title">Positionen</p>-->
                <div class="content">
                  <b-field label="Interne Notiz">
                    <b-input type="textarea" v-model="data.internal_note"></b-input>

                  </b-field>

                </div>
              </article>
            </div>
          </div>
        </div>






        <div class="tile is-parent">
          <article class="tile is-child notification">

<!--            <div class="columns">-->
<!--              <div class="column">-->
                <p class="title">Positionen</p>
<!--              </div>-->
<!--              <div class="column">-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Artikel</button> &nbsp;-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Freier Eintrag</button> &nbsp;-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Gruppe</button> &nbsp;-->
<!--              </div>-->
<!--            </div>-->
            <!--<p class="subtitle">Aligned with the right tile</p>-->
            <div class="content">

              <OrderOutLines v-model="data.orderout_lines" :document_data="data" :disabled="data.sent" v-on:requestupdate="fetchData"></OrderOutLines>
<!--              <DocumentLine :line="data.orderout_lines[0]"></DocumentLine>-->
<!--              <InvoiceEntry v-bind:product="productData[0]" />-->
<!--              <InvoiceEntry v-bind:product="productData[2]" />-->
<!--              <InvoiceEntry v-bind:product="productData[1]" />-->


            </div>
          </article>
        </div>




        <div class="tile is-vertical is-16">
          <div class="tile">


            <div class="tile is-parent is-16">
              <article class="tile is-child notification">
                <!--                  <p class="title">Positionen</p>-->
                <div class="content">
                  <b-field>
                    <b-input type="textarea" rows="12" v-model="data.free_text_post" :disabled="data.sent"></b-input>
                    <template #label>
                      Freitext
                      <!--                        <b-button size="is-small" icon-left="plus" class="ml-5" @click="openBoilerplateComposer" v-if="!data.sent">Textbausteine</b-button>-->
                    </template>
                  </b-field>

                </div>
              </article>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="tile is-parent">
      <article class="tile is-child notification box">
        <div class="field is-grouped">
          <div class="control">
            <router-link class="" to="/orderout/">
              <b-button class="button is-secondary">Zurück</b-button>
            </router-link>
          </div>
<!--          <div class="control" :hidden="data.sent">-->
<!--            <b-button class="button is-secondary" @click="getPdf()"><i class="fa fa-print"></i> Drucken (Vorschau)</b-button>-->
<!--          </div>-->
          <div class="control" :hidden="isNew || data.sent">
            <b-button class="button is-link" @click="update(false)"><i class="fa fa-save"></i> Speichern</b-button>
          </div>
<!--          <div class="control" :hidden="!isNew || data.sent">-->
<!--            <button class="button is-link" @click="create"><i class="fa fa-plus"></i> Erstellen</button>-->
<!--          </div>-->
          <div class="control" :hidden="isNew || data.sent">
            <b-button class="button is-success" @click="finalize"><i class="fa fa-lock"></i> Bestellung absenden</b-button>
          </div>
<!--          <div class="control" :hidden="!data.sent">-->
<!--            <b-button class="button is-danger" @click="notImplemented"><i class="fa fa-trash"></i> Stornieren</b-button>-->
<!--          </div>-->
<!--          <div class="control" :hidden="!data.sent">-->
<!--            <b-button class="button is-primary" @click="getPdf()"><i class="fa fa-print"></i> Drucken</b-button>-->
<!--          </div>-->
<!--          <div class="control" :hidden="!data.sent">-->
<!--            <b-button class="button is-primary is-light" @click="getPdf(template='noprice')"><i class="fa fa-print"></i> Drucken (ohne Preise)</b-button>-->
<!--          </div>-->


        </div>
      </article>
    </div>

  <b-loading :active="isLoading" :is-full-page="true" :can-cancel="false" />
  </div>
</template>

<script>

// import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";
import DatePicker from "@/components/DatePicker";
import BoilerplateComposer from "@/components/BoilerplateComposer";
import {isEqual} from "lodash";
import OrderOutLines from "@/components/OrderOutLines";

export default {
  name: 'OrderOut',
  // props: ['document_type'],
  data() {
            return {
              data: {},
              dataDump: null,
              isNew: null,
              isLoading: true,
              changed: false
            }
  },
  computed: {

    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return "this.data.supplier.name"
      }
    },
    total_net() {
      let total = 0;
      for (let i = 0; i < this.data.orderout_lines.length; i++) {
        total += this.data.orderout_lines[i].amount * this.data.orderout_lines[i].single_price
      }
      return this.finRound(total);
    },
    total_net_discounted() {
      return 0
      // return this.finRound(this.total_net - this.total_discount);
    },
    total_discount() {
      return 0
      // return this.finRound(this.total_net*this.data.sales_discount/100);
    },
    total_cash_discount() {
      let sum = 0;
      // for (let i = 0; i < this.data.orderout_lines.length; i++) {
      //   if (this.data.orderout_lines[i].line_type != 'text' && this.data.orderout_lines[i].line_type != 'text2' && this.data.orderout_lines[i].vat) {
      //     if (this.data.orderout_lines[i].cash_discountable) {
      //       sum += parseFloat(this.lineTotal(this.data.orderout_lines[i].amount, this.data.orderout_lines[i].single_price)) * (1+this.data.orderout_lines[i].vat.rate/100 || 1) * (1-this.data.sales_discount/100) * this.data.cash_discount/100
      //     }
      //   }
      // }

      return sum;
    },

    //TODO vat und total gross mit rabatt berechnen
    total_vat() {
      let sums = [];
      for (let i = 0; i < this.data.orderout_lines.length; i++) {
        if (this.data.orderout_lines[i].line_type != 'text' && this.data.orderout_lines[i].line_type != 'text2' && this.data.orderout_lines[i].vat) {
          if (!sums[this.data.orderout_lines[i].vat.rate]) {
            sums[this.data.orderout_lines[i].vat.rate] = 0
          }
          sums[this.data.orderout_lines[i].vat.rate] += parseFloat(this.lineTotal(this.data.orderout_lines[i].amount, this.data.orderout_lines[i].single_price) * (1-this.data.sales_discount/100))
        }
      }

      let total = 0;
      for (let vat in sums) {
        total += (sums[vat] * vat / 100)
      }

      return total;
    },
    total_gross() {
      return (this.total_net*100 + this.total_vat*100)/100
    }

  },
  components: {
    // SalutationPicker,
    // // InvoiceTypePicker,
    // // DocumentLine,
    OrderOutLines,
    // PaymentConditionPicker,
    // CustomerPicker,
    // ProjectPicker,
    // UserPicker,
    DatePicker
  },
  methods: {
    isChanged: function() {
      //return (JSON.parse(JSON.stringify(this.data)).localCompare(this.dataDump))
      // return JSON.parse(JSON.stringify(this.data).localeCompare(this.dataDump))
      let r = !isEqual(this.data, this.dataDump)
      console.log(r)
      return r
    },
    lineTotal: function(amount, single_price){
      return (Math.round(amount * single_price * 100) / 100).toFixed(2);
    },
    finRound: function(amount){
      return (Math.round(amount * 100) / 100).toFixed(2);
    },
    fetchData: function() {
      let self = this
      this.isLoading = true
      apiservice()
          .get('orderout/' + this.$route.params.id)
          .then(response => {
            this.data = response.data.data
            this.dataDump = JSON.parse(JSON.stringify(response.data.data))
            // this.data.date = new Date(this.data.date) todo check back data
            // self.data.date = this.dateISOFormatter(new Date(self.data.date))
            self.isLoading = false
          })
          .catch(function (error) {
            self.isLoading = false
            this.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    update: function(finalize = false) {



      // this.data.payment_condition_id = this.data.payment_condition ? this.data.payment_condition.id : null;
      // this.data.project_id = this.data.project ? this.data.project.id : null;
      // this.data.responsible_person_id = this.data.responsible_person ? this.data.responsible_person.id : null;

      for (let i=0; i<this.data.orderout_lines.length; i++) {
        if (this.data.orderout_lines[i].vat){
          this.data.orderout_lines[i].vat_id = this.data.orderout_lines[i].vat.id
        }

        if (this.data.orderout_lines[i].unit){
          this.data.orderout_lines[i].unit_id = this.data.orderout_lines[i].unit.id
        }
      }

      //const sendData = JSON.parse(JSON.stringify(this.data.data));
      //sendData.date = this.dateISOFormatter(this.data.date)

      this.isLoading = true;

      if (finalize) {
        this.data.sent = true
      }

      let self = this;
      let toFinalize = finalize
      apiservice()
          .post('orderout/update', this.data)
          .then(function (response) {
              self.$buefy.toast.open({
                message: 'Bestellung erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              self.data = response.data.data
              let preview = response.data.preview
              console.log(preview)
              self.dataDump = JSON.parse(JSON.stringify(response.data.data))
              // self.data.date = new Date(self.data.date) todo check back date
              // self.data.date = this.dateISOFormatter(new Date(self.data.date))
              self.isLoading = false;

              if (toFinalize) {
                self.sendEmail(self.data.supplier.email_order, "Bestellung " + self.data.document_nr, preview);
              }

              console.log(response);
            })
            .catch(function (error) {
              self.isLoading = false;
              self.$buefy.dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);

                //this.data.sent = false;
            });
    },
    finalize: function() {
      this.$buefy.dialog.confirm({
        title: 'Bestellung absenden?',
        message: '<b>Möchten Sie diese Bestellung an den Lieferanten senden??</b><br /><br />Es können danach keine Änderungen mehr vorgenommen werden und die Bestellung wird im E-Mail-Programm zur Verfügung gestellt.<br /><br /><b><i>Achtung: Die Mail muss manuell im E-Mail-Programm abgesendet werden!</i></b>',
        confirmText: 'Ja, jetzt senden',
        cancelText: 'Nein',
        type: 'is-primary',
        icon: 'paper-plane',
        iconPack: 'fa',
        hasIcon: true,
        onConfirm: () => {
          // this.data.sent = true;
          this.update(true);

        }
      })
    },
    dateFormatter: function(date) {
      let d = new Date(date)
      // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
      return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
      // return d.toISOString()
    },
    dateISOFormatter: function (date) {
      if (!(date.getTime() === date.getTime()) || date == null)
        return null
      return date.toISOString().split("T")[0]
    },
    dateParser: function(date) {
      let d = new Date(date);
      return d
      // return `0${d.getMonth()}-0${d.getDate()}-${d.getFullYear()}`
    },
    dateCreator: function() {
      return new Date()
    },
    formatCurrency: function (value) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    },
    notImplemented: function() {
      this.$buefy.toast.open({
        message: 'Aktuell nicht möglich.',
        position: 'is-bottom',
        type: 'is-danger'
      })

    },
    sendEmail(to, subject, body) {
      let mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    }
    ,getPdf: function(template='default') {
      console.log(template)
      this.isLoading = true;
      let self = this;
      let filename = this.documentTypeReadable + "_" + (this.data.document_nr ? this.data.document_nr : "ENTWURF") + "_" + this.data.customer.name + ".pdf";
      apiservice()
          .get("documents/" + this.document_type + "/" + this.data.id + "/pdf/"+template, {responseType:'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
    },
    openBoilerplateComposer: function() {
      let self = this
      this.$buefy.modal.open({
        component: BoilerplateComposer,
        parent: this,
        props: {

        },
        onCancel: function () {
          console.log()
        },
        events: {
          'addBoilerplate': value => {
            self.data.free_text += value + " "
            self.$buefy.toast.open({
              message: '"' + value + '" hinzugefügt.',
              position: 'is-bottom',
              type: 'is-success'
            })
          }
        }
      })
    },


    // BEGIN prevent dirty form to close
    confirmLeave() {
      return window.confirm('Es gibt ungespeicherte Änderungen. Diese werden beim Verlassen nicht gespeichert.')
    },

    confirmStayInDirtyForm() {
      return this.isChanged() && !this.confirmLeave()
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    // END prevent dirty form to close

  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  },



  //// BEGIN prevent dirty form to close

  // beforeRouteLeave (to, from, next) {
  //   // If the form is dirty and the user did not confirm leave,
  //   // prevent losing unsaved changes by canceling navigation
  //   if (this.confirmStayInDirtyForm()){
  //     next(false)
  //   } else {
  //     // Navigate to next view
  //     next()
  //   }
  // },
  //
  // created() {
  //   window.addEventListener('beforeunload', this.beforeWindowUnload)
  // },
  //
  // beforeDestroy() {
  //   window.removeEventListener('beforeunload', this.beforeWindowUnload)
  // },
  //// END prevent dirty form to close
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
